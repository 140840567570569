var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-card", { attrs: { shadow: "always" } }, [
    _c("h4", [_vm._v("Request Number")]),
    _c("h1", [
      _vm._v(
        _vm._s(
          _vm.$attrs.systemGeneratedInvoiceNo
            ? _vm.$attrs.systemGeneratedInvoiceNo
            : "_"
        )
      ),
    ]),
    _vm.$attrs.nftRespDetails.isOverdue
      ? _c(
          "div",
          {
            staticClass: "status-tag",
            staticStyle: {
              color: "#F26961",
              "background-color": "#FFE6E1",
              width: "90%",
              "margin-bottom": "10px",
              "margin-top": "-5px",
            },
          },
          [_vm._v("Over Due")]
        )
      : _vm._e(),
    _c("div", { staticClass: "flex-column", staticStyle: { flex: "1" } }, [
      _c("div", { staticStyle: { "min-height": "50px" } }, [
        _c(
          "div",
          { staticClass: "summary-block-title" },
          [
            _c(
              "div",
              {
                staticClass: "flex-between-row",
                staticStyle: { "vertical-align": "middle" },
              },
              [
                _c("span", { staticStyle: { "line-height": "28px" } }, [
                  _vm._v(
                    _vm._s(_vm.$attrs.isFinancier ? "Supplier" : "Financer") +
                      " Information"
                  ),
                ]),
                !_vm.$attrs.isFinancier
                  ? _c(
                      "span",
                      [
                        !_vm.$attrs.isViewOnly
                          ? _c(
                              "el-button",
                              {
                                staticClass: "select-action-button",
                                attrs: { round: "", outline: "", size: "mini" },
                                on: { click: _vm.handleOpenFinancerDialog },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      float: "left",
                                      margin: "0",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("Select Financer "),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c("el-divider"),
            !_vm.$attrs.isFinancier
              ? [
                  _vm.$attrs.status === _vm.FINANCE_STATUS[0] ||
                  _vm.$attrs.view === "CREATE_FINANCE"
                    ? _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.selectedFinancers,
                            border: "",
                            "header-cell-style": _vm.HEADER_CELL_STYLE,
                            "cell-style": _vm.CELL_STYLE,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "No.", "min-width": "40px" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("h4", [
                                        _vm._v(_vm._s(`${scope.$index + 1}`)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2832456752
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Name", "min-width": "150px" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("h4", [
                                        _vm._v(_vm._s(scope.row.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1216283729
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "", width: "50px", fixed: "right" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-popconfirm",
                                        {
                                          attrs: {
                                            "confirm-button-text": "Delete",
                                            "cancel-button-text": "No, Thanks",
                                            icon: "el-icon-info",
                                            "icon-color": "red",
                                            title:
                                              "Are you sure to delete this?",
                                          },
                                          on: {
                                            confirm: function ($event) {
                                              return _vm.removeLine(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "reference",
                                              disabled: _vm.$attrs.isViewOnly,
                                              size: "small",
                                              icon: "el-icon-delete",
                                              type: "danger",
                                              circle: "",
                                            },
                                            slot: "reference",
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3734247830
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$attrs.financier
                    ? _c(
                        "div",
                        { staticClass: "div-frac-12-avg sunmary-info" },
                        [
                          _c("span", [_vm._v("Name:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$attrs.financier
                                  ? _vm.$attrs.financier.name
                                  : "_"
                              )
                            ),
                          ]),
                          _c("span", [_vm._v("discount rate:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$attrs.financier.discountRatePercentage
                                  ? _vm.$attrs.financier
                                      .discountRatePercentage + "%"
                                  : "_"
                              )
                            ),
                          ]),
                          _c("span", [_vm._v("lateChargesFee:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$attrs.financier.lateChargesPercentage
                                  ? _vm.$attrs.financier.lateChargesPercentage +
                                      "%"
                                  : "_"
                              ) + "%"
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.$attrs.isFinancier
              ? [
                  _vm.$attrs.supplier
                    ? _c(
                        "div",
                        { staticClass: "div-frac-12-avg sunmary-info" },
                        [
                          _c("span", [_vm._v("Name:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$attrs.supplier
                                  ? _vm.$attrs.supplier.name
                                  : "_"
                              )
                            ),
                          ]),
                          _c("span", [_vm._v("Requestor:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$attrs.supplier.requesterName
                                  ? _vm.$attrs.supplier.requesterName
                                  : "_"
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticStyle: { "min-height": "100px", "padding-top": "10px" },
          attrs: { "v-loading": _vm.$attrs.rightSummaryLoading },
        },
        [
          _c(
            "div",
            { staticClass: "summary-block-title margin-top-10" },
            [_c("span", [_vm._v("Financing Summary")]), _c("el-divider")],
            1
          ),
          _vm.feesAndChargesList && _vm.feesAndChargesList.length > 0
            ? _c(
                "div",
                _vm._l(_vm.feesAndChargesList, function (item) {
                  return _c("div", { key: item.id }, [
                    _c(
                      "h4",
                      {
                        staticStyle: {
                          "padding-top": "10px",
                          color: "#0F376A",
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _c(
                      "div",
                      { staticClass: "div-frac-spec-2-1 sunmary-info" },
                      [
                        _c("span", [_vm._v("Request Amount:")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.numberWithCommas(item.amount, 2))),
                        ]),
                        _c("span", [_vm._v("Discount Rate Percentage:")]),
                        _c("span", [
                          _vm._v(_vm._s(item.discountRatePercentage) + "%"),
                        ]),
                        _c("span", [
                          _vm._v(
                            "Amount " +
                              _vm._s(
                                _vm.$attrs.isFinancier
                                  ? "Payable"
                                  : "Receivable"
                              ) +
                              ":"
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.numberWithCommas(
                                item.amountReceivableAfterDiscountRate,
                                2
                              )
                            )
                          ),
                        ]),
                        _c("span", [_vm._v("Late Charge Percentage:")]),
                        _c("span", [
                          _vm._v(_vm._s(item.lateChargesPercentage) + "%"),
                        ]),
                        _c("span", [_vm._v("Late Charges Fee:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.numberWithCommas(
                                item.lateChargesFeeBasedOnAmount,
                                2
                              )
                            )
                          ),
                        ]),
                        _vm.$attrs.isFinancier
                          ? _c("span", [_vm._v("Platform Fee Percentage:")])
                          : _vm._e(),
                        _vm.$attrs.isFinancier
                          ? _c("span", [
                              _vm._v(_vm._s(item.platformFeePercentage) + "%"),
                            ])
                          : _vm._e(),
                        _vm.$attrs.isFinancier
                          ? _c("span", [_vm._v("Platform Fee:")])
                          : _vm._e(),
                        _vm.$attrs.isFinancier
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.numberWithCommas(item.platformFee, 2)
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _c("div", [_vm._v(" _ ")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }